import request from '@/utils/request'


// 查询抽奖奖品列表
export function listPrize(query) {
  return request({
    url: '/prize/prizes/list',
    method: 'get',
    params: query
  })
}

// 查询抽奖奖品分页
export function pagePrize(query) {
  return request({
    url: '/prize/prizes/page',
    method: 'get',
    params: query
  })
}

// 查询抽奖奖品详细
export function getPrize(data) {
  return request({
    url: '/prize/prizes/detail',
    method: 'get',
    params: data
  })
}

// 新增抽奖奖品
export function addPrize(data) {
  return request({
    url: '/prize/prizes/add',
    method: 'post',
    data: data
  })
}

// 修改抽奖奖品
export function updatePrize(data) {
  return request({
    url: '/prize/prizes/edit',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/prize/prizes/changeIsOpen',
    method: 'post',
    params: data
  })
}
// 删除抽奖奖品
export function delPrize(data) {
  return request({
    url: '/prize/prizes/delete',
    method: 'post',
    data: data
  })
}
