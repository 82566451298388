<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="奖品名称" prop="prizeName" >
        <a-input v-model="form.prizeName" placeholder="请输入奖品名称" />
      </a-form-model-item>
      <a-form-model-item label="奖品图片" prop="prizeCover" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.prizeCover"
          :showUploadList="false"
          :customRequest="handleChange"
        >
          <img v-if="form.prizeCover" :src="form.prizeCover" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="奖品概率" prop="probability" >
        <a-input-number :step="0.01" :precision="2"  :min="0" :max="1" v-model="form.probability" placeholder="请输入奖品概率" />
      </a-form-model-item>
      <a-form-model-item label="奖品类型" prop="isOffline" >
        <a-select placeholder="请选择奖品类型" @change="updateType" v-model="form.isOffline">
          <a-select-option :key="0">会员</a-select-option>
          <a-select-option :key="1">实体奖品</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="vipdays==0" label="会员天数" prop="vipdays" >
        <a-input-number :step="1" :precision="0"  :min="0"  v-model="form.vipDays" placeholder="请输入奖品概率" />
      </a-form-model-item>
<!--      <a-form-model-item label="是否禁用" prop="isBan" >-->
<!--        <a-input v-model="form.isBan" placeholder="请输入是否禁用" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="地址" prop="address" >-->
<!--        <a-input v-model="form.address" placeholder="请输入地址" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPrize, addPrize, updatePrize } from '@/api/prize/prize'
import UploadFileToVOD from "@/api/vod";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      uploadLoading: false,
      vipdays: 1,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        prizeName: null,

        prizeCover: null,

        probability: null,
        vipDays: null,
        isOffline: null,

        isBan: null,

        address: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    updateType(e) {
        this.vipdays=e
    },
    handleChange(info) {
      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          that.form.prizeCover = fileName[0];//文件名
          that.uploadLoading = false;
        }
      });
      that.uploadLoading = false;
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        prizeName: null,
        prizeCover: null,
        probability: null,
        isBan: null,
        vipDays: null,
        isOffline: null,
        address: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form={
        id: null,

          prizeName: null,

          prizeCover: null,

          probability: null,

          isBan: null,

          address: null,

          createTime: null,

          remark: null,

      }
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPrize({"id":id}).then(response => {
        this.form = response.data
        this.vipdays = this.form.isOffline
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePrize(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPrize(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
